import CraftPage, { GetPageStaticProps, getStaticProps as craftGetStaticProps } from './info/[url]'

export default CraftPage

export const getStaticProps: GetPageStaticProps = async ({ locale, params }) =>
  craftGetStaticProps({
    locale,
    params: {
      ...params,
      url: '/',
    },
  })
