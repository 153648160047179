import { PageOptions } from '@graphcommerce/framer-next-pages'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import {
  PageMeta,
  LayoutHeader,
  GetStaticProps,
  MetaRobots,
  LayoutTitle,
} from '@graphcommerce/next-ui'
import { GetStaticPaths } from 'next'
import { DefaultPageQuery, DefaultPageDocument } from '../../graphql/DefaultPage.gql'
import { graphqlSharedClient, graphqlSsrClient } from '../../lib/graphql/graphqlSsrClient'
import { PageContentRenderer } from '../../components/CraftCMS/PageContentRenderer'
import { LayoutNavigation, LayoutNavigationProps } from '../../components'
import { LayoutDocument } from '../../components/Layout/Layout.gql'
import { PageTemplateNames } from '../../components/CraftCMS/PageTemplate'
import { WithLocaleBaseProps } from '../../types'
import { getCraftPageStaticPaths } from '../../queries/getCraftPageStaticPaths'
import { Container, Typography } from '@mui/material'
import { getFetchPolicyForKey } from '../../lib/graphql/fetchPolicy'

export type CraftPageProps = DefaultPageQuery & WithLocaleBaseProps
type RouteProps = { url: string }
type GetPageStaticPaths = GetStaticPaths<RouteProps>
export type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, CraftPageProps, RouteProps>

const pageCraftRoutePrefix = 'page'
export const hardcodedSlugs = { home: 'homepage', noRoute: 'no-route' }

function CraftPage(props: CraftPageProps) {
  const { pageEntries, locale } = props
  const page = pageEntries?.[0]

  const title = page?.title ?? ''
  const url = page?.url

  let metaRobots
  const metaRobotsValue = page?.metaRobots?.toLowerCase()
  if (typeof metaRobotsValue !== 'undefined' && metaRobotsValue !== 'none') {
    metaRobots = metaRobotsValue.toLowerCase().split(',').flat(1) as MetaRobots[]
  } else {
    metaRobots = 'none' as MetaRobots
  }

  return (
    <>
      <PageMeta
        title={page?.metaTitle ?? title ?? ''}
        metaDescription={page?.metaDescription ?? ''}
        metaRobots={metaRobots}
        canonical={`/${page?.url}`}
      />
      {title && url !== `${pageCraftRoutePrefix}/${hardcodedSlugs.home}` && (
        <Container sx={(theme) => ({ marginBottom: theme.spacings.sm })}>
          <Typography variant='h1' component='h1'>
            {title}
          </Typography>
        </Container>
      )}
      {!!locale && page?.pageContent && (
        <PageContentRenderer
          template={page?.template as PageTemplateNames | undefined}
          pageContent={page?.pageContent}
          locale={locale}
        />
      )}
    </>
  )
}

CraftPage.pageOptions = {
  Layout: LayoutNavigation,
} as PageOptions

export default CraftPage

// eslint-disable-next-line @typescript-eslint/require-await
export const getStaticPaths: GetPageStaticPaths = async ({ locales = [] }) => {
  if (process.env.NODE_ENV === 'development') return { paths: [], fallback: 'blocking' }

  const ignoreUrls = [hardcodedSlugs.home]

  const paths = (
    await Promise.all(
      locales.map(async (locale) => {
        const localePaths = await getCraftPageStaticPaths(graphqlSsrClient(locale), locale)
        return localePaths.filter((path) => {
          if (typeof path === 'string') {
            return !ignoreUrls.includes(path)
          }

          return !ignoreUrls.includes(path.params.url[0])
        })
      }),
    )
  ).flat(1)

  return { paths, fallback: 'blocking' }
}

export const getStaticProps: GetPageStaticProps = async ({ locale, params }) => {
  const incomingUrlKey = params?.url ? params.url : '/'

  const urlKey = incomingUrlKey === '/' ? hardcodedSlugs.home : incomingUrlKey

  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)

  const conf = client.query({ query: StoreConfigDocument })
  const page = staticClient.query({
    query: DefaultPageDocument,
    variables: {
      url: [`${pageCraftRoutePrefix}/${urlKey}`],
      rootCategory: (await conf).data.storeConfig?.root_category_uid ?? '',
    },
    fetchPolicy: getFetchPolicyForKey('defaultPage'),
  })

  const layout = staticClient.query({
    query: LayoutDocument,
    fetchPolicy: getFetchPolicyForKey('layout'),
  })

  if (!(await page).data.pageEntries?.[0]) return { notFound: true }

  return {
    props: {
      locale,
      ...(await page).data,
      ...(await layout).data,
      up: { href: '/', title: 'Home' },
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate: 60 * 60 * 2,
  }
}
